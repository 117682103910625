<template>
  <div>
    <el-tabs v-model="state" @tab-click="handleCustTypeChange">
      <el-tab-pane label="景区提现" name="1">
        <span slot="label">
          景区提现
          <i v-if="getFirstAuth.withdrawsight > 0">({{ getFirstAuth.withdrawsight }})</i>
        </span>
        <el-table :data="withdraw" style="width: 100%">
          <el-table-column
            v-for="(value, key) in tableList"
            :key="key"
            :prop="key"
            :label="value"
            align="center"
          ></el-table-column>
          <el-table-column label="回调" align="center">
            <template slot-scope="scope">
              <div
                style="color:#417cad;cursor:pointer"
                v-if="scope.row.failRes"
                @click="showFail(scope.row.failRes)"
              >{{ scope.row.failRes. err_code_des}}</div>
            </template>
          </el-table-column>
          <el-table-column label="暂缓" align="center">
            <template slot-scope="scope">
              <el-button @click="reject(scope.row)" type="danger" size="mini">拒绝支付</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="0" label="历史">
        <!-- 历史 -->
        <el-table :data="withdraw" style="width: 100%" size="small">
          <el-table-column
            v-for="(value, key) in tableList2"
            :key="key"
            :prop="key"
            :label="value"
            min-width="60px"
            align="center"
          ></el-table-column>
          <el-table-column label="回调及备注" width="300px" align="center">
            <template slot-scope="scope">
              <el-button
                @click="showFail(scope.row.failRes)"
                type="danger"
                size="mini"
                v-if="scope.row.failRes"
              >失败</el-button>
              <el-button
                @click="showFail(scope.row.remark)"
                type="primary"
                size="mini"
                v-if="scope.row.remark"
              >备注</el-button>
              <el-button
                @click="showFail(scope.row.successRes)"
                type="success"
                size="mini"
                v-if="scope.row.successRes"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!-- 非优惠券商家提现 -->

    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
    <el-dialog :visible.sync="show" top="8vh">
      <div v-for="(value,key) in failRes" :key="key" class="showclass">
        <div style="min-width:120px">{{key}}:</div>
        <div style="margin-left:20px">{{value}}</div>
      </div>
    </el-dialog>
  </div>
</template>
	
<script>
import { getPaymentList, refusePayment } from "@/api/api";
import { isString } from "url/util";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      page: 1,
      state: "1",
      tableList: {
        withdrawSn: "流水号",
        custId: "客户编号",
        custName: "客户名称",
        sightName: "景区",
        amount: "提现金额",
        stateName: "状态",
        genTime: "提交时间"
      },
      tableList2: {
        withdrawSn: "流水号",
        custId: "客户编号",
        custName: "客户名称",
        sightName: "景区",
        amount: "提现金额",
        stateName: "状态",
        genTime: "提交时间"
      },
      count: 20,
      total: 0,
      withdraw: [],
      show: false,
      failRes: {}
    };
  },
  mounted() {
    this.getPaymentListFn();
  },
  computed: {
    ...mapGetters(["getFirstAuth"])
  },
  methods: {
    countSight() {
      return this.getFirstAuth.withdrawsight;
    },

    getPaymentListFn() {
      const that = this;
      getPaymentList({
        page: that.page,
        limit: that.count,
        state: that.state
      }).then(res => {
        if (res.result) {
          that.withdraw = res.data.list;
          that.total = res.data.total;
          if (that.state == 1) {
            that.getFirstAuth.withdrawsight = that.total;
            that.$store.dispatch("setFirstAuth", that.getFirstAuth);
          }
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPaymentListFn();
    },
    getDay(item) {
      var eTime = new Date(item.replace(new RegExp("-", "gm"), "/"));
      return eTime.getMonth() + 1 + "-" + eTime.getDate();
    },
    //提现状态，0：成功，1，申请，2，拒绝
    reject(row) {
      const that = this;
      this.$prompt("请输入拒绝的原因", "拒绝", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入拒绝的原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          refusePayment({
            withdrawSn: row.withdrawSn,
            value: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已拒绝该条提现申请"
              });
              this.page = 1;
              this.getPaymentListFn();
            }
          });
          //账户处理
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    handleCustTypeChange(e) {
      this.state = e.name;
      this.page = 1;
      this.total = 0;
      this.withdraw = [];
      this.getPaymentListFn();
    },

    showFail(item) {
      if (isString(item)) {
        this.failRes = {
          备注: item
        };
      } else {
        this.failRes = item;
      }
      this.show = true;
    }
  }
};
</script>

<style scoped>
.list-g h4 {
  min-width: 300px;
}
.list-g p {
  border-bottom: 1px solid #999;
}
i {
  color: red;
}
.showclass {
  margin: 0 40px;
  line-height: 42px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
}
</style>